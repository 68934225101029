.button--add{
  
    width: 25px !important;
    display: flex !important;
    justify-content: center !important;             
    align-items: center !important;
    height: 25px !important;
    min-width: 25px !important;
    min-height: 25px !important;
    // margin-bottom: 20px;
    // margin-top: 20px;
    // // margin-left:20px;
    // background-color: #f7f9fc;
    // background-color: lightgray;
  
}
.titleLocation{
  width: 100%;
  height: 30px;
  background-color: hsla(0,0%,94.9%,.7294117647058823);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #333;
  font-weight: bold;
}
.closeIcon{
    color: red;
    cursor: pointer;
    margin-left: 2px;
}
.nameTree{
  cursor: pointer;
  &:hover{
    color: #1890ff;
  }
}