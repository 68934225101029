.buttonAddOffice {
    width: 25px;
    height: 100px;
    min-height: 25px;
    min-width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dee6eb;
    ;

}

.wrap-select-border {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid lightgray;

    .noborder .ant-tabs-nav .ant-tabs-nav-add,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-select-multiple .ant-select-selection-item {
        border: none !important;
        outline: none !important;
    }

    .ant-select-open .ant-select-selector {
        border-color: red !important;
        box-shadow: none !important;
    }

}

.wrap-select-noborder {
    border: none;
}