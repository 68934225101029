@import "../inspection.scss";
@import "../../../styles/css/vars.scss";
.detailChecklist {
  margin: 24px 16px;
  padding: 20px;
  margin-top: 0 !important;
  margin-bottom: 80px !important;
  height: calc(100% - 130px);
  box-shadow: 0px 1px 3px rgba(0, 29, 46, 0.08);
  border-radius: 4px;
  background: #ffffff;
  color: #252733;
  &__mobile {
    padding: 16px;
    margin: 0;
    box-shadow: none;
    background: $bg_mobile;
  }
  .checklist {
    &__name {
      font-weight: bold;
      word-break: break-word;
    }
    &-group__number,
    &__type {
      font-weight: bold;
    }
    .text-error {
      padding: 24px;
      border: 1px solid #b8babb;
      border-radius: 5px;
    }
    &__input {
      padding-top: 4px !important;
      .select-group {
        width: 50% !important;
        .title {
          font-weight: bold;
        }
      }
    }
    &__history {
      padding: 40px;
      background-color: #f7f9fc;
      max-height: 350px;
      overflow: auto;
      .title {
        padding-bottom: 20px;
        font-weight: bold;
        font-size: 20px;
        color: #252733;
      }
      .time {
        font-weight: bold;
      }
    }
    .bookmark {
      padding-bottom: 30px;
    }
  }
  .listOverview {
    margin: -8px;
    .timeCheck {
      padding-left: 0 !important;
    }
    .rowTimeLine {
      padding-right: 0 !important;
    }
    .picker {
      font-size: 16px;
      .ant-input {
        font-size: 16px;
      }
      .ant-select-selector {
        height: 35px;
        .ant-select-selection-item {
          padding-top: 2px;
        }
      }
      .ant-picker-input > input {
        font-size: 16px;
      }
    }
  }
  .hasCheck > .ant-collapse-header,
  .hasCheck > .headerMachine {
    background-color: $bg_checking !important;
    .text,
    .ant-collapse-arrow {
      color: black !important;
      font-weight: bold;
    }
    .status {
      color: $checking;
      .text-status {
        padding: 4px;
        padding-left: 0;
      }
    }
  }
  .errorCheck > .ant-collapse-header,
  .errorCheck > .headerMachine {
    background-color: $bg_error_check !important;
    .text,
    .ant-collapse-arrow {
      color: black !important;
      font-weight: bold;
    }
    .status {
      color: $error_check;
      .text-status {
        padding: 4px;
        padding-left: 0;
      }
    }
  }
  .disableCheck > .ant-collapse-header,
  .disableCheck > .headerMachine {
    background-color: $bg_disable_check !important;
    .text,
    .ant-collapse-arrow {
      color: black !important;
      font-weight: bold;
    }
    .status {
      color: $disable_check;
      .text-status {
        padding: 4px;
        padding-left: 0;
      }
    }
  }
  .unCheck > .ant-collapse-header,
  .unCheck > .headerMachine {
    background-color: $bg_un_check !important;
  }
  .done > .ant-collapse-header,
  .done > .headerMachine {
    background-color: $bg_complete_check;
    .text,
    .ant-collapse-arrow {
      color: black !important;
      font-weight: bold;
    }
    .status {
      color: $complete_check;
      .text-status {
        padding: 4px;
        padding-left: 0;
      }
    }
  }
  .listItems {
    margin-bottom: 32px !important;
    .picker {
      font-size: 16px !important;
      .ant-select-selector {
        height: 33px;
        .ant-select-selection-item {
          padding-top: 2px;
        }
      }
      .ant-picker-input {
        font-size: 16px;
      }
    }
    .listMachine {
      margin-top: 16px !important;
      .working-status,
      .none-input {
        font-size: 14px;
        color: #6c757d;
      }
    }
    .listMachine:first-child {
      margin-top: 0 !important;
    }
    .ant-collapse-content-box {
      padding-top: 0 !important;
    }
    .ant-collapse-header {
      border: 1px solid #dfe6ec;
      border-radius: 4px 4px 0px 0px;
      .text {
        font-weight: bold;
      }
    }
    .headerMachine {
      padding: 16px;
      border: 1px solid #dfe6ec;
      border-radius: 4px 4px 0px 0px;
      .text {
        font-weight: bold;
      }
    }
    .ant-collapse-content-box {
      padding: 0;
    }
    .dot {
      height: 10px;
      width: 10px;
      margin-right: 8px;
      border-radius: 5px;
      &--hasCheck {
        background-color: #ffd130;
      }
      &--done {
        background-color: #1fc32f;
      }
      &--errorCheck {
        background-color: #ca5c54;
      }
    }
  }
}
.sticky {
  position: fixed !important;
  top: 54px;
  z-index: 10000;
  background: white;
  &--mobile {
    position: fixed !important;
    top: 54px;
    z-index: 10000;
    background: white;
  }
}
.header-fixed {
  position: fixed !important;
  top: 105px;
  z-index: 1;
  width: calc(100% - 40px);
}
.confirmButton {
  position: fixed;
  bottom: 0;
  padding-right: 8px;
  padding-left: 8px;
  background-color: #fff;
  margin: 0 !important;
  border: 1px solid #dfe6ec;
  &__mobile {
    width: 100%;
    padding: 0;
    align-items: center;
    text-align: center;
    border: none;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
  }
  & > .report {
    height: 100%;
    background-color: #fff;
    color: #0080cb;
    font-weight: bold;
    padding: 13px;
  }
  & > .save {
    height: 100%;
    background-color: #0080cb;
    color: #fff;
    font-weight: bold;
    padding: 13px;
  }
}
.icon__confirm {
  width: 14px;
  height: 16px;
  margin-right: 4px;
  margin-bottom: 2px;
}
.drawerGroup {
  font-weight: bold;
  .ant-drawer-content {
    border-radius: 12px 12px 0px 0px;
  }
  .ant-drawer-title {
    font-weight: bold;
    font-size: 20px;
  }
  .ant-drawer-header {
    border-bottom: 0;
  }
  .search {
    position: absolute;
    width: calc(100% - 24px);
    z-index: 10000;
    top: 46px;
    background-color: #fff;
  }
  .searchGroup {
    width: 100%;
  }
  .rowData {
    padding-top: 16px;
    width: 100%;
    height: auto;
  }
  .ant-drawer-body {
    padding: 8px;
  }
  .itemGroup {
    padding: 13px 12px;
    color: #252733;
    border-bottom: 1px solid #dfe6ec;
    &:nth-child(even) {
      background-color: #fff;
    }
    &:nth-child(even) {
      background: #f7f9fc;
    }
  }
}
.drawerSave {
  font-weight: bold;
  .ant-drawer-content {
    border-radius: 12px 12px 0px 0px;
  }
  .ant-drawer-title {
    font-weight: bold;
    font-size: 20px;
  }
  .ant-drawer-body {
    overflow-y: hidden;
  }
  .ant-drawer-header {
    border-bottom: 0;
  }
  .save:hover,
  .draft:hover,
  .report:hover,
  .trouble:hover {
    cursor: pointer;
  }
  .save {
    padding: 13px;
    background: #0080cb;
    color: #fff;
    margin-bottom: 8px;
    font-size: 18px;
  }
  .draft {
    border: 1px solid #0080cb;
    padding: 13px;
    background: #fff;
    color: #0080cb;
    font-size: 18px;
  }
  .report {
    border: 1px solid #0080cb;
    padding: 13px;
    background: #fff;
    color: #0080cb;
    margin-bottom: 8px;
    font-size: 18px;
  }
  .trouble {
    border: 1px solid #0080cb;
    padding: 13px;
    background: #fff;
    color: #0080cb;
    font-size: 18px;
  }
}
.modal {
  .text-warning {
    font-weight: 600;
    text-align: center;
  }
  .icon {
    height: 46px;
    padding: 8px 0;
  }
}
