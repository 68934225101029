.BannerMotion {
    font-size: 18px;
    width: 100%;
    background-color: #f16900;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 0;
    opacity:0.96 ;
}

.textmarquee {
    color: white;

}

.wrapNameItem {
    &:hover {
        color: #0080CB;
        cursor: pointer;

    }

    .nameItem {
        font-weight: bold;
        text-decoration: underline;

    }
}
.wrapClearIcon{
    padding: 0 12px;
    cursor: pointer;
    &:hover{
      color: red;
    }
}

.wrapMotion{
    position: relative;
    width: 100%;
    height: 100%;
  overflow:hidden;
}

.textMotion{
    color:white;
    
    position: absolute;
    
    top:0;
    left: 0%;
    animation: motion 40s linear;
    white-space:nowrap;

}
@keyframes motion{
    0% {
        left: 50%;
      }
      100% {
        transform: translateX(-100%);
      }
}