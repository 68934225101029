@import "../../styles/css/vars.scss";

.comboCheckbox {
  /* margin: 0 15px; */

  .checkbox-list {
    display: grid;
    margin-left: 17px;
  }

  span {
    /* font-size: 16px !important; */
  }

  .chooseMachine {
    color: #a9a9a9;
  }
}

.comboCheckboxRow {
  .m {
    display: block;
    margin: 0;
    padding: 0;
    cursor: pointer;

    &-title {
      margin-bottom: 8px;
    }
    &-more {
    }
    &-space {
      margin: 12px 0;
    }
  }

  .chooseMachine {
    display: block;
    opacity: 0.3;
  }
  .checkbox-all {
    display: none;
  }

  .checkbox-list {
  }

  span {
    /* font-size: 16px !important; */
  }
}
