@import "../../styles/css/vars.scss";
.cardDetail {
  font-size: 16px;
  .ant-card-head {
    background-color: $main_color;
    color: white;
    font-size: 16px;
  }
}
.clearButton {
  color: red;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
