@import "../../styles/css/vars.scss";

.machine-content {
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    font-size: $small_size;
  }

  thead > tr:last-child th {
    padding: 10px;
  }

  .ant-table-thead > tr > th {
    border-bottom: 3px solid $color-border-3;
    border-top: 1px solid $color-border-3;
    background-color: $white;
  }

  .ant-table-tbody tr:nth-child(even) {background-color: $bg_machine;}
  .list-machine .ant-table-tbody tr:nth-child(odd) {background-color: $bg_machine;}
  .list-machine .ant-table-tbody tr:nth-child(even) {background-color: unset;}

  .list-machine th {
    white-space: nowrap;
  }

  .tableProject td {
    text-align: center;
  }

  .tableView {
    background: transparent !important;
  }

  .tableView .ant-table-thead>tr>th {
    background: transparent !important;
  }

  .tableView * {
    border: none !important;

  }

  .tableView .ant-table-thead tr th.ant-table-cell {
    text-align: start !important;
    padding-top: 5px !important;

  }

  .ant-table-thead .timeline-history {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    border-left: 1px solid black;
    border-right: 1px solid black;
  }

  .ant-table-tbody .timeline-history {
    padding: 0;
    vertical-align: top;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-top: none;
  }

  .tableColor {
    margin-top: -5px;
  }

  .wrap-namefile {
    display: flex;
    align-items: center;
  }

  .btn-deletefile {
    min-width: 15px !important;
    max-height: 30px !important;
    font-size: 14px !important;
    margin-left: 10px;
  }

  .viewmachine {
    padding: 20px !important;
    border-bottom: 1px solid lightgray !important;
  }

  .columnColor {
    background-color: #0080cb;
    display: inline-block;
    padding: 5px;
    box-sizing: border-box;
  }

  .btn-attach {
    border: 1px solid lightgrey;
    justify-content: center;
    align-items: center;
    padding: 3px 0 0 0;
    width: 100%;
    max-width: 55px;
  }

  .btn-attach:first-child {
    margin-right: 5px;
  }

  @media (max-width: 991px) {
    .btn-attach:first-child {
      margin-right: 0px;
    }
  }

  .table-changelog tr th {
    text-align: left !important;
  }

  .ant-collapse {
    border: none;
  }

  .ant-collapse > .ant-collapse-item {
    border-bottom: none;
  }

  .ant-collapse-content>.ant-collapse-content-box {
    padding: 0px
  }

  .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    background-color: $grey;
    color: rgba(0, 0, 0, 0.85);
    font-weight: bold;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header .first-icon {
    font-size: 24px;
    color: $main_color;
    vertical-align: bottom;
    margin-right: 4px;
  }

  .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
    padding-left: 16px;
  }

  .machine-file {
    position: relative;
    padding: 4px 0px;
    color: $black;
    word-wrap: break-word;
  }

  .machine-file a {
    color: $black;
  }

  .machine-file:hover a {
    color: $main_color;
    text-decoration: underline;
  }

  .machine-file .first-icon {
    position: absolute;
    left: -24px;
    font-size: 20px;
    vertical-align: middle;
    color: $main_color;
  }

  // .ant-collapse>.ant-collapse-item-disabled>.ant-collapse-header {
  //   background-color: $disable_check;
  //   color: white;
  //   opacity: 0.8;
  // }

  .text-1line {
    display: inline-block;
    max-width: calc(100% - 30px);
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  .avatar .ant-image{
    img {
      height: 300px;
      width: 100%;
      object-fit: contain;
      -o-object-fit: contain;
    }
  }

  .btnDelete:hover,
  .btnDelete:active {
    border-color: #e3473e;
    color: #d14139;
  }
}

.display-1-line {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.display-2-line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.display-5-line {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}