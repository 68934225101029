@import "../../styles/css/vars.scss";

.ant-divider-horizontal {
  margin: 8px;
}

.formSearchTemp {
  font-size: 18px !important;
}

.textError {
  font-size: 14px;
  color: red;
}

.btn {

  &--edit:hover,
  &--edit:focus {
    color: $second_color;
    border-color: $second_color;
  }

  &--delete:hover,
  &--delete:focus {
    color: $btn_error_b;
    border-color: $btn_error_text;
  }

  &--download:hover,
  &--download:focus {
    color: $main_color;
    border-color: $main_color;
  }
}

.customDivider {
  margin: 0;
}

.custom {
  padding-top: 24px;
  padding-bottom: 24px;
}

.wrapActionDownload {
  width: 100%;
  height: 70px;
  position: fixed;
  border: 1px solid lightgrey;
  bottom: 0px;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: white;

  .action {
    height: 100%;
    padding-right: 30px;
  }
}