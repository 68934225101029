.wrap-image {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .wrap-action {
        .button-action {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
            width: 30px;
            height: 30px;
            min-width: 30px;

            .icon-action {
                font-size: 15px;
                color: white;
            }
        }

        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-left: 15px;
    }
}

.overlay-crop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    opacity: 0.5;
    z-index: 9999;
}

.wrap-modal-crop {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    max-height: 97%;
    position: fixed;
    top: 50%;
    left: 50%;
    border-radius: 5px;
    min-width: 700px;
    z-index: 99999999999;
    transform: translate(-50%, -50%);
.wrap-modal-title{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
}
    .modal-crop {
        overflow-y: auto;
        overflow-x: auto;
        max-width: 100%;

    }

    .edit {
        margin-top: 10px;

        &-scale {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
        }

        &-title {
            margin-bottom: -8px;
        }
    }

    .wrap-modal-button {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        &-mobile {
            justify-content: center !important;
        }
    }
}

.buttonWrapUpload {
    transition: all ease-in-out 0.5s;

    .uploadWrap {
        color: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        animation: led 3s ease-in-out infinite;

        .uploadIcon {
            font-size: 23px;
            animation: motion1 3s ease-in-out infinite;
        }
    }
}

@keyframes motion1 {
    0% {
        transform: translateY(0%);
    }

    50% {
        transform: translateY(-30%);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes led {
    50% {

        color: #0080CB;
    }

    100% {
        color: black;
    }
}