.content-notification {
  border: 1px solid #afafaf99;
  padding: 12px;
  .box-notification {
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    padding-bottom: 48px;
    .title-box {
      border-bottom: 1px solid red;
      padding: 12px 0;
      line-height: 3;
    }
  }
}
