@import "../../styles/css/vars.scss";

/*custom scrollbar */
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #d1d0d0;
}

::-webkit-scrollbar-thumb {
  background-color: #918e8e;
  border-radius: 20px !important;
  opacity: 0.5;
  border: 2px solid #dfdede;
}

::-webkit-scrollbar-button {
  background-color: transparent;
  width: 0;
  height: 0;
}

// Screen
.dashboard {
  margin-top: 54px;
  height: calc(100% - 50px);
  padding: 12px;
  background: #f0f2f5;
  .chart-bg-white {
    background: white;
    box-shadow: 0 1px 3px rgba(0, 29, 46, 0.08);
    border-radius: 4px;
    padding: 16px;
    .title {
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      &--icon {
        font-size: 20px;
        padding-right: 10px;
        color: #0080cb;
      }
    }
    .radio {
      padding-right: 8px;
    }
  }
  .chart {
    border: 1px solid #dfe6ec;
    background-color: #f7f9fc;
    box-shadow: 0 1px 3px rgba(0, 29, 46, 0.08);
    border-radius: 4px;
    // height: 100%;
    .title {
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      &--icon {
        font-size: 20px;
        padding-right: 10px;
        color: #0080cb;
      }
    }
    .radio {
      padding-right: 8px;
    }
  }
  .dynamic-chart {
    background: white;
    box-shadow: 0 1px 3px rgba(0, 29, 46, 0.08);
    border-radius: 4px;
    margin: 8px;
  }
  .overview {
    box-shadow: 0 1px 3px rgba(0, 29, 46, 0.08);
    border-radius: 4px;
    color: #fff;
    .data {
      font-size: 30px;
      font-weight: bold;
    }
    .text {
      font-size: 16px;
    }
    .icon {
      border: 1px solid #fff;
      border-radius: 50%;
      width: 64px;
      height: 64px;
      color: white;
      text-align: center;
      & > .anticon {
        width: 22px;
        height: 22px;
        font-size: 22px;
      }
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  .ant-select-selection-item {
    text-align: center;
  }
}
.box {
  box-shadow: 0 1px 3px rgba(0, 29, 46, 0.08);
  transition: opacity 0.4s;
  border-radius: 4px;
  height: 100%;
}

.box-title {
  background-color: #fff;
  padding: 12px 10px 0px 10px;
  font-weight: bold;
  color: black;
  // height: 38px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  .icon {
    color: #0080cb;
    font-size: 20px;
  }
  .titleForm {
    font-size: 16px;
    padding-left: 8px;
    padding-top: 10px;
  }
}

.box-content {
  padding: 10px;
  height: calc(100% - 34px);
  overflow-y: auto;
  background-color: #fff;
  border-radius: 4px;
}
.itemWorkReport {
  padding-bottom: 13px;
  &:last-child {
    padding-bottom: 0;
  }
}
////@at-root
.square {
  margin: 5px 7px;
  min-width: 10px;
  height: 10px;
  background: $main_color;
}

.border-bottom-custom {
  border-bottom: 1px dotted;
}

.tblShortcut {
  height: 358px;
  font-size: 13px;
}

.tblWork {
  font-size: 14px;
  color: #666;

  &-body {
    font-size: 14px !important;
    color: #666;
    padding-right: 10px;
    overflow: auto;
    width: 100%;
  }
}

.tblLeft {
  th {
    padding: 6px 8px !important;
    // color: #666 !important;
    font-size: 15px !important;
    background: #fff !important;
  }

  .ant-table-thead > tr > th {
    text-align: left !important;
    border-bottom: 1px dotted #333;
  }

  tr:not(.ant-table-measure-row) > td {
    padding: 4px 10px !important;
    font-size: 13px !important;
    font-weight: 700;
    color: #666;

    a {
      color: #3498db !important;
    }

    .ant-btn.btnS {
      font-size: 11px !important;
      height: auto !important;
      white-space: normal;
      font-weight: 700 !important;
    }

    .btnOrange {
      background-color: #ea632d !important;
      color: #fff !important;
      border-color: #dc5a44;
    }
  }

  tr:nth-of-type(odd) {
    background-color: #efefef;
  }

  .ant-table-tr > th {
    font-weight: bold;
    text-align: left !important;
  }
}
.ant-table-body {
  overflow-y: auto !important;
}
.btnBlueNew {
  background-color: #2ba6e1 !important;
  color: #fff !important;
  border-color: #1890ff;
}

.header-img {
  margin-bottom: 5px;
  margin-right: 7px;
  height: 25px;
}

.header-label-color {
  font-size: 16px;
  // color: #4d7eae;
  margin: 0 !important;
}

.scroll-table-item-config {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  overflow-y: auto;
  max-height: 304px;
  min-height: 304px;

  a {
    color: $main_color;
  }
}
.tblWork-body {
  a {
    color: $main_color;
  }
}

#tbSetting {
  width: 100%;

  tr > th {
    height: 30px;
    background-color: darkgrey;
    text-align: center;
    color: white;
  }

  tr > th {
    width: 33%;
    border: 1px solid #ddd;
  }

  tr > td {
    width: 33%;
    border: 1px solid #ddd;
  }

  tr > td {
    padding-left: 10px;
  }
}
.avatar .ant-image{
  img {
    height: 300px;
    width: 100%;
    object-fit: contain;
    -o-object-fit: contain;
  }
}
