

@import "../../styles/css/vars.scss";

.first-icon{
    font-size: 24px;
    color: $main_color;
    vertical-align: bottom;
    margin-right: 4px;
}
.ant-collapse {
    border: none;
  }

  .ant-collapse > .ant-collapse-item {
    border-bottom: none;
  }
.text-1line {
    display: inline-block;
    max-width: calc(100% - 30px);
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    
  }
