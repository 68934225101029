.custom-checklist {
  .ant-form-item {
    margin-bottom: 10px;
  }
  .ant-collapse-header {
      padding-bottom: 0 !important;
  }
  .ant-collapse-content-box {
      padding-top: 0 !important;
      padding-left: 58px !important;
  }
}
