@import "../../styles/css/vars.scss";

.calendar-content {
  thead > tr:last-child th {
    padding: 0px;
  }

  .fc-day {
    // cursor: pointer;
  }

  table a, .fc-popover a {
    color: unset;
  }

  table a:hover, .fc-popover a:hover {
    color: unset;
    text-decoration: none;
  }

  .fc-event-title {
    text-overflow: ellipsis;
  }

  .fc-more-link {
    padding: 2px;
    border-radius: 3px;
  }

  .fc-daygrid-dot-event:hover, .fc-more-link:hover {
    background: rgba(0,0,0,.1);
  }

  .weekend-day {
    background: #f7f7f7;
  }

  .fc-dayGridMonth-view,.fc-timeGridWeek-view,.fc-timeGridDay-view,.fc-popover {
    .event {
      &--confirmed {
        background: $main_color;
        border: 1px solid $main_color;
        color: white;
      }
  
      &--plan {
        background: $green;
        border: 1px solid $green;
        color: white;
      }

      &--repair {
        background: $text-danger;
        border: 1px solid $text-danger;
        color: white;
      }

      &--calibration {
        background: $second_color;
        border: 1px solid $second_color;
        color: white;
      }
    }
  }

  .fc-popover a:hover {
    color: white;
  }

  .event {
    &--confirmed {
      .fc-list-event-dot {
        border-color: $main_color;
      }
    }

    &--plan {
      .fc-list-event-dot {
        border-color: $green;
      }
    }

    &--repair {
      .fc-list-event-dot {
        border-color: $text-danger;
      }
    }

    &--calibration {
      .fc-list-event-dot {
        border-color: $second_color;
      }
    }
  }

  .fc-daygrid-event-dot {
    display: none;
  }

  .ant-modal-body {
    .ant-form-item-label {
      white-space: unset;
    }
  }
}