.detailItem {
  padding-top: 10px;
  .part:first-child {
    border: 1px solid #dfe6ec;
    border-top: 1px solid #dfe6ec;
  }
  .part {
    border: 1px solid #dfe6ec;
    border-top: 0px;
    padding: 16px;
    .radio-mobile {
      .ant-radio {
        font-size: 20px;
        &-inner {
          width: 20px;
          height: 20px;

          &::after {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
    .replace-item {
      padding: 10px;
      border: 1px solid #e6e6e6;
      border-radius: 4px;
      margin-bottom: 4px !important;
    }
    .previousValue,
    .rangeValue,
    .accumulateValue {
      color: #6c757d;
      font-size: 12px;
    }
    .fillValue {
      cursor: pointer;
      text-decoration: underline;
      color: #0080cb;
      font-size: 12px;
    }
    .titleItem {
      font-weight: bold;
    }
    .picker {
      height: 40px;
      background: #fafafa;
      border-color: #e6e6e6 !important;
      border-radius: 4px;
      & > .ant-picker-input > input {
        font-size: 16px;
        text-align: center;
        font-weight: 600;
      }
      &--mobile {
        height: 50px !important;
        font-size: 20px;
        & > .ant-picker-input > input {
          font-size: 24px !important;
          text-align: center;
          font-weight: 600;
        }
      }
      .ant-picker-clear {
        font-size: 24px;
      }
    }
    .am-flexbox-item,
    .am-image-picker-item.am-image-picker-upload-btn,
    .am-image-picker-item {
      max-height: 150px;
      max-width: 150px;
    }
  }
}
